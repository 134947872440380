import {
  faBuilding,
  faHouse,
  faSpinner,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import { useSetData } from '../../context/dataContext'
import { useSetFormData } from '../../context/formContext'
import useDataLayer from '../../context/trackingContext'
import { getPostalCodeStats } from '../../services/firestore'

import styles from './postalCode.module.css'

const noDataErrorMessage =
  'Tyvärr finns inte detta postnummer i vår databas. Det är möjligt att det inte fanns tillräckliga försäljningar i området.'

const updateURLParams = ({ postalCode, housingType }) => {
  if (window.history && window.history.pushState) {
    const url = new URL(window.location)
    if (postalCode && housingType) {
      url.searchParams.set('postal-code', postalCode)
      url.searchParams.set('housing-type', housingType)
    } else {
      url.searchParams.delete('postal-code')
      url.searchParams.delete('housing-type')
    }
    window.history.pushState({}, '', url)
  }
}

export const PostalCode = () => {
  const setFormData = useSetFormData()
  const setData = useSetData()
  const dataLayer = useDataLayer()
  const formRef = useRef()
  const [submitError, setSubmitError] = useState(null)

  const defaultValues = useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return {
      postalCode: params.get('postal-code'),
      housingType: params.get('housing-type'),
    }
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setFocus,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  const housingType = useWatch({ control, name: 'housingType' })

  const registerPostalCode = register('postalCode', {
    minLength: 5,
    maxLength: 5,
    required: true,
  })

  useEffect(() => {
    if (formRef && housingType) {
      formRef.current.dispatchEvent(
        new Event('submit', {
          cancelable: true,
          bubbles: true,
        })
      )
    }
  }, [formRef, housingType])

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        setFormData(data)
        window.location.hash = ''
        // re-submitting the same form should trigger scrolling
        setData(null)

        const result = await getPostalCodeStats(
          data.postalCode,
          data.housingType
        )

        dataLayer({
          event:
            result === null ? 'initial-form-no-data' : 'initial-form-success',
          postalCode: data.postalCode,
          housingType: data.housingType,
        })

        if (result === null) {
          return setSubmitError(noDataErrorMessage)
        }

        setSubmitError(null)
        setData(result)
        updateURLParams(data)
      },
      [setFormData, setData, setSubmitError, dataLayer]
    )
  )

  const resetForm = useCallback(() => {
    window.location.hash = ''
    reset({}, { keepErrors: false })
    setFormData(null)
    setSubmitError(null)
    setData(null)
    updateURLParams({})
    // there seems to be something async or an intermediate rendering going
    // on if we focus immediately
    setTimeout(() => setFocus('postalCode'), 200)
  }, [reset, setSubmitError, setData, setFormData, setFocus])

  const isPostalCodeError =
    errors.postalCode &&
    touchedFields.postalCode &&
    ['required', 'maxLength', 'minLength'].includes(errors.postalCode.type)

  return (
    <form
      className={`container narrow ${styles.form}`}
      ref={formRef}
      onSubmit={onSubmit}
    >
      <header>
        <h1>Bomarknaden</h1>
        <div className={styles.subtitle}>
          <span>by</span>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://www.movesta.se/?utm_source=bomarknaden.nu&amp;utm_content=movesta-se-logo"
            target="_blank"
          >
            <img src="logo-white.png" />
          </a>
        </div>
      </header>
      <div className={styles.formFieldsContainer}>
        <div className={styles.postalWrapper}>
          <label htmlFor="search-postal">
            <h3>Postnummer</h3>
          </label>
          <label
            className={`${styles.postalInputContainer} ${styles.postalInput} ${
              isPostalCodeError ? styles.error : ''
            }`}
          >
            <input
              {...registerPostalCode}
              id="search-postal"
              type="number"
              onWheel={(e) => e.target.blur()}
            />
            <button type="reset" onClick={resetForm}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  visibility: getValues('postalCode') ? 'visible' : 'hidden',
                }}
              />
            </button>
          </label>
          <span className={styles.fieldError}>
            {isPostalCodeError && 'Ange ett giltigt svenskt postnummer'}
          </span>
        </div>
        <div className={styles.housingType}>
          <div>
            <input
              type="radio"
              {...register('housingType', { required: true })}
              id="housing-type-apartment"
              value="apartments"
            />
            <label htmlFor="housing-type-apartment">
              <div>
                <FontAwesomeIcon icon={faBuilding} />
              </div>
              <h3>Lägenhet</h3>
            </label>
            <input
              type="radio"
              {...register('housingType', { required: true })}
              id="housing-type-house"
              value="houses"
            />
            <label htmlFor="housing-type-house">
              <div>
                <FontAwesomeIcon icon={faHouse} />
              </div>
              <h3>Hus</h3>
            </label>
          </div>
          <div className={styles.fieldError}>
            {errors.housingType?.type === 'required' && 'Välj en bostadstyp'}
          </div>
        </div>
      </div>
      {submitError && <div className={styles.submitError}>{submitError}</div>}
      <div className={styles.cta}>
        <button type="submit">
          Visa marknadstrend
          {isSubmitting && <FontAwesomeIcon icon={faSpinner} />}
        </button>
      </div>
    </form>
  )
}
