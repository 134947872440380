import { faSackDollar } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { useWindowSize } from 'use-window-size-hook'

import { CustomLabel, getMargin } from '../chartUtil'
import BottomArrow from '../components/BottomArrow/BottomArrow'
import { PageHeader } from '../components/Pageheader/PageHeader'
import { COLORS } from '../constants'
import useData from '../context/dataContext'
import { convertMonths, toListOfObjects } from '../dataUtils'

export function SqmPriceChart() {
  const {
    screenLayout: { isMdOrAbove },
  } = useWindowSize()
  const data = (useData() || {}).stats?.price_per_m2
  const chartData = useMemo(
    () =>
      data &&
      toListOfObjects({
        ...data,
        x: convertMonths(data.x),
      }),
    [data]
  )

  if (!data) return <></>

  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div className="container" ref={ref}>
          <PageHeader
            color={COLORS.blue}
            faIcon={faSackDollar}
            title="Kvadratmeterpris (tkr)"
          />
          <ResponsiveContainer className="chart">
            <LineChart
              data={chartData}
              margin={getMargin()}
              style={{ fontSize: '18px' }}
              width={730}
            >
              {inView && (
                <>
                  <Line
                    dataKey="y"
                    dot={{
                      stroke: COLORS.blue,
                      fill: COLORS.blue,
                      strokeWidth: isMdOrAbove ? 7 : 3,
                    }}
                    fill={COLORS.white}
                    label={(props) => (
                      <CustomLabel {...props} formatter={(v) => v / 1000} />
                    )}
                    stroke={COLORS.blue}
                    strokeWidth={isMdOrAbove ? 5 : 3}
                  />

                  <XAxis
                    axisLine={false}
                    dataKey="x"
                    stroke={COLORS.blue}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    dataKey="y"
                    domain={[
                      (dataMin) => dataMin * 0.95,
                      (dataMax) => dataMax * 1,
                    ]}
                    hide={true}
                    tickLine={false}
                    type="number"
                  />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
          <BottomArrow style={{ marginTop: '16px' }} to="time-on-market" />
        </div>
      )}
    </InView>
  )
}
