export const VerticalSeparator = () => (
  <svg
    fill="none"
    height="34"
    viewBox="0 0 1 34"
    width="1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line opacity="0.6" stroke="#0D1E16" x1="0.5" x2="0.5" y2="34" />
  </svg>
)
