import { createContext, useContext, useState } from 'react'

const context = createContext()
const useFormData = () => useContext(context).formData
export default useFormData
export const useSetFormData = () => useContext(context).setFormData

export function FormProvider(props) {
  const [formData, setFormData] = useState(null)
  return <context.Provider {...props} value={{ formData, setFormData }} />
}
