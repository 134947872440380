import { createContext, useContext } from 'react'
import TagManager from 'react-gtm-module'

const context = createContext()

const useDataLayer = () => useContext(context).dataLayer
export default useDataLayer

export function TrackingProvider(props) {
  let dataLayer = () => {}

  if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    }
    TagManager.initialize(tagManagerArgs)

    dataLayer = (data) => {
      TagManager.dataLayer({ dataLayer: { ...data } })
    }
  }

  return <context.Provider {...props} value={{ dataLayer }} />
}
