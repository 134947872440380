import React, { useCallback } from 'react'
import { InView } from 'react-intersection-observer'

import ScrollProgressBar from '../components/ScrollProgressBar/ScrollProgressBar'
import useData from '../context/dataContext'

import { CTA } from './cta/CTA'
import NumberOfHomes from './numberOfHomes/NumberOfHomes'
import { PostalCode } from './postalCode/PostalCode'
import { Score } from './score/Score'
import { SqmPriceChart } from './SqmPriceChart'
import { TimeOnMarketChart } from './TimeOnMarketChart'

export default function PagesWrapper() {
  const isData = useData() !== null

  return (
    <>
      <div className="scroll-wrapper">
        {/*  Page 0: postal code  */}
        <ScrollPage className="parallax-layer" hash="" id="postal-code">
          <PostalCode />
        </ScrollPage>

        {isData && (
          <>
            <ScrollProgressBar />

            {/*  Page 1: score + percentile  */}
            <ScrollPage className="parallax-layer beige" id="score">
              <Score />
            </ScrollPage>

            {/*  Page 2: sqm price  */}
            <ScrollPage
              className="parallax-layer lightblue"
              id="square-meter-price"
            >
              <SqmPriceChart />
            </ScrollPage>

            {/*  Page 3: time on market  */}

            <ScrollPage className="parallax-layer blue" id="time-on-market">
              <TimeOnMarketChart />
            </ScrollPage>

            {/*  Page 4: Number of homes  */}

            <ScrollPage className="parallax-layer beige" id="number-of-homes">
              <NumberOfHomes />
            </ScrollPage>

            {/*  Page 4: options  */}

            <ScrollPage className="parallax-layer" id="options">
              <CTA />
            </ScrollPage>
          </>
        )}
      </div>
    </>
  )
}

const ScrollPage = ({ id, hash, children, ...props }) => {
  const onInView = useCallback(
    (inView) => {
      if (!inView) return
      document.location.hash = hash !== undefined ? hash : id
    },
    [id, hash]
  )

  return (
    <InView
      as="div"
      {...props}
      delay={100}
      id={id}
      threshold={0.9}
      onChange={onInView}
    >
      {children}
    </InView>
  )
}
