import {
  ScoreNeutral,
  ScoreSlightlyUpwards,
  ScoreSlightlyDownwards,
  ScoreDownwards,
  ScoreUpwards,
} from './scoreIcons'

export const thresholdToScoreData = [
  {
    from: 0.0,
    to: 0.25,
    color: '#FB2333',
    title: 'Nedåtgående trend',
    image: ScoreDownwards,
    summaryTextFn: (housingTypeLabel) =>
      `Trenden för bostadsmarknaden för ${housingTypeLabel} i området är nedåtgående.`,
    emoji: '⬇️',
  },
  {
    from: 0.25,
    to: 0.475,
    title: 'Svagt nedåtgående trend',
    image: ScoreSlightlyDownwards,
    summaryTextFn: (housingTypeLabel) =>
      `Trenden för bostadsmarknaden för ${housingTypeLabel} i området är svagt nedåtgående.`,
    color: '#F67710',
    emoji: '⤵️',
  },
  {
    from: 0.475,
    to: 0.525,
    title: 'Stillastående trend',
    image: ScoreNeutral,
    summaryTextFn: (housingTypeLabel) =>
      `Trenden för bostadsmarknaden för ${housingTypeLabel} i området är neutral.`,
    color: '#F0AE23',
    emoji: '⏸️',
  },
  {
    from: 0.525,
    to: 0.75,
    title: 'Svagt uppåtgående trend',
    image: ScoreSlightlyUpwards,
    summaryTextFn: (housingTypeLabel) =>
      `Trenden för bostadsmarknaden för ${housingTypeLabel} i området är svagt uppåtgående.`,
    color: '#0BBF50',
    emoji: '⤴️',
  },
  {
    from: 0.75,
    to: 1.0,
    title: 'Uppåtgående trend',
    image: ScoreUpwards,
    color: '#0BBF50',
    summaryTextFn: (housingTypeLabel) =>
      `Trenden för bostadsmarknaden för ${housingTypeLabel} i området är uppåtgående.`,
    emoji: '⬆️',
  },
]

// Example texts from Martin:
// Kvadratmeterpriset, försäljningstiden och antalet objekt ute till försäljning är i stort oförändrade.
// Kvadratmeterpriset har gått upp, försäljningstiden är kortare och antalet objekt ute till försäljning har minskat.

// If we want, we can replace {price_per_m2}, {days_on_market}, {num_sold} with one of the options below conditioned on "magnitude"
// (If we do so we should make sure to not pick the same ones in a row, for variation)
// We can also just skip this and replace these with an empty string initially!
export const emphasisOptions = {
  some: ['något', 'en del', 'lite'],
  much: ['markant', 'ordentligt', 'kraftigt'],
}

export const partialScoreMapOrder = [
  'score_price_per_m2',
  'score_days_on_market',
  'score_num_sold',
]

// Here's a mapping from the each combination of trends in 'price_per_m2', 'days_on_market' (NOTE positive means shorter!) and num_sold (positive means more).
export const partialScoreTexts = {
  '-,+,-':
    'Kvadratmeterpriset har gått ned{price_per_m2} samtidigt som försäljningstiden har ökat{days_on_market}. Antalet försäljningar har gått ned{num_sold}.',
  '-,+,/':
    'Kvadratmeterpriset har gått ned{price_per_m2} samtidigt som försäljningstiden har ökat{days_on_market}. Antalet försäljningar har däremot inte förändrats nämnvärt.',
  '-,+,+':
    'Kvadratmeterpriset har gått ned{price_per_m2} samtidigt som försäljningstiden har ökat{days_on_market}. Antalet försäljningar har också gått upp{num_sold}.',
  '-,/,-':
    'Kvadratmeterpriset har gått ned{price_per_m2} men försäljningstiden är relativt oförändrad. Antalet försäljningar har också gått ned{num_sold}.',
  '-,/,/':
    'Kvadratmeterpriset har gått ned{price_per_m2} men försäljningstiden är relativt oförändrad. Antalet försäljningar har heller inte förändrats nämnvärt.',
  '-,/,+':
    'Kvadratmeterpriset har gått ned{price_per_m2} men försäljningstiden är relativt oförändrad. Antalet försäljningar har också gått upp{num_sold}.',
  '-,-,-':
    'Kvadratmeterpriset har gått ned{price_per_m2} men försäljningstiden har samtidigt minskat{days_on_market}. Antalet försäljningar har också gått ned{num_sold}.',
  '-,-,/':
    'Kvadratmeterpriset har gått ned{price_per_m2} men försäljningstiden har samtidigt minskat{days_on_market}. Antalet försäljningar har dock inte förändrats nämnvärt.',
  '-,-,+':
    'Kvadratmeterpriset har gått ned{price_per_m2} men försäljningstiden har samtidigt minskat{days_on_market}. Antalet försäljningar har samtidigt gått upp{num_sold}.',
  '/,+,-':
    'Kvadratmeterpriset har inte förändrats nämnvärt men däremot har försäljningstiden gått upp{days_on_market}. Antalet försäljningar har samtidigt gått ned{num_sold}.',
  '/,+,/':
    'Kvadratmeterpriset har inte förändrats nämnvärt men däremot har försäljningstiden gått upp{days_on_market}. Antalet försäljningar är relativt oförändrat.',
  '/,+,+':
    'Kvadratmeterpriset har inte förändrats nämnvärt men däremot har försäljningstiden gått upp{days_on_market}. Antalet försäljningar har också ökat{num_sold}.',
  '/,/,-':
    'Kvadratmeterpriset och försäljningstiden är relativt stabila men antalet försäljningar har gått ned{num_sold}.',
  '/,/,/':
    'Varken kvadratmeterpriset, försäljningstiden eller antalet försäljningar har förändrats nämnvärt.',
  '/,/,+':
    'Varken kvadratmeterpriset eller försäljningstiden har förändrats nämnvärt. Antalet försäljningar har dock gått upp{num_sold}.',
  '/,-,-':
    'Kvadratmeterpriset har inte förändrats nämnvärt samtidigt som försäljningstiden har gått ner{days_on_market}. Antalet försäljningar har också minskat{num_sold}.',
  '/,-,/':
    'Kvadratmeterpriset har inte förändrats nämnvärt samtidigt som försäljningstiden har gått ner{days_on_market}. Antalet försäljningar är relativt oförändrat.',
  '/,-,+':
    'Kvadratmeterpriset har inte förändrats nämnvärt samtidigt som försäljningstiden har gått ner{days_on_market}. Antalet försäljningar har också ökat{num_sold}.',
  '+,+,-':
    'Kvadratmeterpriset har gått upp{price_per_m2} men försäljningstiden har samtidigt ökat{days_on_market}. Antalet försäljningar har också minskat{num_sold}.',
  '+,+,/':
    'Kvadratmeterpriset har gått upp{price_per_m2} men försäljningstiden har samtidigt ökat{days_on_market}. Antalet försäljningar är relativt oförändrat.',
  '+,+,+':
    'Kvadratmeterpriset har gått upp{price_per_m2} men försäljningstiden har samtidigt ökat{days_on_market}. Även antalet försäljningar har ökat{num_sold}.',
  '+,/,-':
    'Kvadratmeterpriset har gått upp{price_per_m2} och försäljningstiden är relativt oförändrad. Antalet försäljningar har samtidigt minskat{num_sold}.',
  '+,/,/':
    'Kvadratmeterpriset har gått upp{price_per_m2} samtidigt som både försäljningstiden och antalet försäljningar är relativt oförändrade.',
  '+,/,+':
    'Kvadratmeterpriset har gått upp{price_per_m2} och försäljningstiden är relativt oförändrad. Antalet försäljningar har samtidigt ökat{num_sold}.',
  '+,-,-':
    'Kvadratmeterpriset har gått upp{price_per_m2} samtidigt som försäljningstiden har gått ner{days_on_market}. Antalet försäljningar har minskat{num_sold}.',
  '+,-,/':
    'Kvadratmeterpriset har gått upp{price_per_m2} samtidigt som försäljningstiden har gått ner{days_on_market}. Antalet försäljningar är relativt oförändrat.',
  '+,-,+':
    'Kvadratmeterpriset har gått upp{price_per_m2} samtidigt som försäljningstiden har gått ner{days_on_market}. Antalet försäljningar har ökat{num_sold}.',
}
