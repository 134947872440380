import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const environment =
  process.env.NODE_ENV === 'development' ||
  !process.env.REACT_APP_FIREBASE_PROJECT_ID
    ? 'local'
    : process.env.REACT_APP_FIREBASE_PROJECT_ID.split('-')[0]

export function initSentry(history) {
  const integrations = []

  if (history) {
    integrations.push(
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      })
    )
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    // adjust if we ever get a very high load
    tracesSampleRate: 1.0,
    // don't set to high, otherwise we get a HTTP 413 error
    // (for large deeply nested objects) and the event is not logged
    normalizeDepth: 5,
    environment,
    enabled: process.env.REACT_APP_SENTRY_ENABLED === 'on',
    integrations,
  })
}
