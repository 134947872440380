import { faSpinner, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import Feedback from '../../components/Feedback/Feedback'
import Modal from '../../components/Modal/Modal'
import useData from '../../context/dataContext'
import useFormData from '../../context/formContext'
import { sendEmail } from '../../services/firestore'

import styles from './cta.module.css'

export function CTA() {
  const initialFormData = useFormData()
  const data = useData()
  const [showDataModal, setShowDataModal] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted, touchedFields },
  } = useForm({
    mode: 'onChange',
  })

  const onSubmit = useCallback(
    async (values) => {
      const email = values.email
      await sendEmail(email, initialFormData, data)
      reset()
    },
    [reset, initialFormData, data]
  )

  return (
    <div className="container">
      <div className={styles.ctacontainer}>
        <div className={styles.email}>
          <h2>Håll koll på trenden</h2>
          <p>
            Få uppdateringar om utvecklingen i ditt område. Inget annat. Din
            epostadress delas inte med någon annan.
          </p>
          <div>
            <form
              className={styles.emailform}
              id="tracking-email-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                {...register('email', {
                  required: 'E-postadress är obligatorisk',
                  setValueAs: (v) => v?.trim(),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'E-postadress är ogiltig',
                  },
                })}
                className={styles.forminput}
                disabled={isSubmitting}
                placeholder={isSubmitted ? 'Skickats!' : 'Din e-post'}
              />
              <button
                className={styles.formbutton}
                disabled={isSubmitting}
                type="submit"
              >
                <span>Skicka</span>
                <FontAwesomeIcon
                  className={isSubmitting ? styles.spinning : undefined}
                  icon={isSubmitting ? faSpinner : faArrowRight}
                />
              </button>
            </form>

            <p className={styles.formHelperText}>
              {touchedFields.email && errors?.email?.message ? (
                <strong>{errors.email.message}</strong>
              ) : (
                <>
                  Genom att skicka din epost godkänner du vår{' '}
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://www.movesta.se/integritetspolicy/"
                    target="_blank"
                  >
                    integritetspolicy
                  </a>
                </>
              )}
            </p>
          </div>
        </div>
        <div className={styles.movesta}>
          <h2>Går du i säljtankar?</h2>
          <p>
            Våga ta steget! Köp ditt nästa drömhem med Movestas{' '}
            <i>Säljgaranti</i> som trygghet.
          </p>
          <div className={styles.movestalinks}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              className={styles.formbutton}
              href="https://www.movesta.se/?utm_source=bomarknaden.nu&amp;utm_content=movesta-se-cta"
              id="tracking-cta-link-movestase"
              target="_blank"
            >
              Läs mer på movesta.se
            </a>
          </div>
        </div>
        <footer className={styles.footer}>
          <button onClick={() => setShowDataModal(true)}>
            Hur beräknas trenden
          </button>
          <button onClick={() => setShowFeedbackModal(true)}>Feedback</button>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.movesta.se/integritetspolicy/" target="_blank">
            Integritetspolicy
          </a>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://www.movesta.se/om-oss/?utm_source=bomarknaden.nu&amp;utm_content=movesta-se-omoss"
            target="_blank"
          >
            Om oss
          </a>
        </footer>
        {showFeedbackModal && (
          <Feedback onHide={() => setShowFeedbackModal(false)} />
        )}
        <Modal
          className={styles.disclaimer}
          close={() => setShowDataModal(false)}
          isOpen={showDataModal}
        >
          <h3>Hur beräknas trenden</h3>
          <p>
            De uppgifter som presenteras på Bomarknaden baseras på publik
            transaktionsdata från försäljningar i närområdet av det postnummer
            som anges - alltså inte bara inom det enskilda postnumret. Notera
            att felaktigheter av flera olika typer kan förekomma i datan.
          </p>
          <p>
            Trendindikationen baseras på utvecklingen i framförallt
            kvadratmeterpris och försäljningstid de senaste 6 månaderna. Den är
            ingen prognos av hur marknaden kommer att utvecklas utan endast en
            sammanfattning av hur den har sett ut nyligen.
          </p>
        </Modal>
      </div>
    </div>
  )
}
