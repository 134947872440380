import React, { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'

import styles from './cookieBanner.module.css'

const propTypes = {}
const defaultProps = {}

const COOKIE_KEY = 'set'

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['cookie'])
  const [showBanner, setShowBanner] = useState(!cookies[COOKIE_KEY])
  const onHideBanner = useCallback(() => {
    setCookie(COOKIE_KEY, true, { path: '/' })
    setShowBanner(false)
  }, [setCookie, setShowBanner])

  if (!showBanner || navigator?.userAgent === 'ReactSnap') return null

  return (
    <div className={styles.wrapper}>
      <div>
        <p className={styles.text}>
          Genom att klicka OK så samtycker du till lagring av cookies för att
          förbättra din upplevelse av webbplatsen. Läs gärna mer om cookies i
          vår {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.movesta.se/integritetspolicy" target="_blank">
            integritetspolicy.
          </a>
        </p>
        <button size="large" variant="primary" onClick={onHideBanner}>
          OK
        </button>
      </div>
    </div>
  )
}

CookieBanner.propTypes = propTypes
CookieBanner.defaultProps = defaultProps

export default CookieBanner
