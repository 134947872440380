import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './bottomArrow.module.css'

function BottomArrow({ to, ...props }) {
  return (
    <div {...props} className={styles.container} id="tracking-bottom-arrow">
      <a aria-label="Gå till nästa del" href={`#${to}`}>
        <FontAwesomeIcon icon={faArrowDown} />
      </a>
    </div>
  )
}

BottomArrow.propTypes = {
  to: PropTypes.string.isRequired,
}

export default BottomArrow
