import { useEffect, useState } from 'react'

import { COLORS } from '../../constants'

import styles from './scrollProgressBar.module.css'

const ScrollProgressBar = () => {
  const [progressPct, setProgressPct] = useState(0)
  const [color, setColor] = useState('transparent')

  const progressSteps = {
    '': 0,
    score: 20,
    'square-meter-price': 40,
    'time-on-market': 60,
    'number-of-homes': 80,
    options: 100,
  }

  const colors = {
    score: COLORS.green,
    'square-meter-price': COLORS.blue,
    'time-on-market': COLORS.white,
    'number-of-homes': COLORS.blue,
    options: COLORS.white,
  }

  useEffect(() => {
    const listener = window.addEventListener('hashchange', (e) => {
      const page = e.target.location.hash?.replace('#', '')
      setProgressPct(progressSteps[page] || 0)
      setColor(colors[page] || 'transparent')
    })
    return () => window.removeEventListener('hashchange', listener)
  }, [])

  return (
    <div className={styles.scrollProgressBar}>
      <div style={{ width: `${progressPct}%`, backgroundColor: color }}></div>
    </div>
  )
}

export default ScrollProgressBar
