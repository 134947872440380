import format from 'date-fns/format'
import sv from 'date-fns/locale/sv'

export const toListOfObjects = (obj) => {
  /*
    {a: [1, 2], b: [3, 4]} 
     -->
    [{a: 1, b: 3}, {a: 2, b: 4}]
  */
  delete obj.x_ref
  delete obj.y_ref

  const keys = Object.keys(obj)

  return obj[keys[0]].map((_, i) =>
    Object.fromEntries(keys.map((key) => [key, obj[key][i]]))
  )
}

export function convertMonths(months) {
  return months.map((month) =>
    format(
      new Date(month),
      window.innerWidth < 800 && window.innerWidth > 450 ? 'MMM' : 'MMMM',
      {
        locale: sv,
      }
    )
  )
}
