import { createContext, useContext, useState } from 'react'

const context = createContext()

const useData = () => useContext(context).data
export default useData
export const useSetData = () => useContext(context).setData

export function DataProvider(props) {
  const [data, setData] = useState(null)
  return <context.Provider {...props} value={{ data, setData }} />
}
