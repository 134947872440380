import { useEffect } from 'react'

import { DataProvider } from './context/dataContext'
import { FormProvider } from './context/formContext'
import { TrackingProvider } from './context/trackingContext'
import PagesWrapper from './pages/PagesWrapper'
import { initSentry } from './sentry'
import './App.css'
import CookieBanner from './components/CookieBanner/CookieBanner'

initSentry()

function App() {
  useEffect(() => {
    window.location.hash = ''
  }, [])

  return (
    <TrackingProvider>
      <FormProvider>
        <DataProvider>
          <PagesWrapper />
        </DataProvider>
      </FormProvider>
      <CookieBanner />
    </TrackingProvider>
  )
}

export default App
