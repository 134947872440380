import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export async function getPostalCodeStats(postalCode, housingType) {
  const col = collection(db, 'bomarknaden')
  const document = await getDoc(doc(col, `${postalCode}_${housingType}`))
  return document?.data() || null
}

export async function sendFeedback(feedbackObj) {
  const col = collection(db, 'user_feedback')
  await addDoc(col, {
    ...feedbackObj,
    type: 'bomarknaden-internal',
    created_at: serverTimestamp(),
  })
}

export async function sendEmail(email, initialFormData, data) {
  const col = collection(db, 'email_list')
  await addDoc(col, {
    email,
    created_at: serverTimestamp(),
    type: 'bomarknaden-interest',
    initialFormData,
    data,
  })
}
