import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { XAxis, ResponsiveContainer, LineChart, Line, YAxis } from 'recharts'
import { useWindowSize } from 'use-window-size-hook'

import { CustomLabel, getMargin } from '../chartUtil'
import BottomArrow from '../components/BottomArrow/BottomArrow'
import { PageHeader } from '../components/Pageheader/PageHeader'
import { COLORS } from '../constants'
import useData from '../context/dataContext'
import { convertMonths, toListOfObjects } from '../dataUtils'

export function TimeOnMarketChart() {
  const {
    screenLayout: { isMdOrAbove },
  } = useWindowSize()
  const data = (useData() || {}).stats?.days_on_market
  const chartData = useMemo(
    () =>
      data &&
      toListOfObjects({
        ...data,
        x: convertMonths(data.x),
      }),
    [data]
  )
  if (!data) return <></>

  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div className="container" ref={ref}>
          <PageHeader
            color={COLORS.white}
            faIcon={faCalendarDays}
            title="Försäljningstid (dagar)"
          />
          <ResponsiveContainer className="chart">
            <LineChart
              data={chartData}
              margin={getMargin()}
              style={{ fontSize: '18px' }}
              width={730}
            >
              {inView && (
                <>
                  <Line
                    dataKey="y"
                    dot={{
                      stroke: COLORS.white,
                      fill: COLORS.white,
                      strokeWidth: isMdOrAbove ? 7 : 3,
                    }}
                    fill={COLORS.white}
                    label={CustomLabel}
                    stroke={COLORS.white}
                    strokeWidth={isMdOrAbove ? 5 : 3}
                  />
                  <XAxis
                    axisLine={false}
                    dataKey="x"
                    stroke={COLORS.white}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    dataKey="y"
                    domain={[
                      (dataMin) => dataMin - 3,
                      (dataMax) => dataMax + 3,
                    ]}
                    hide={true}
                    tickLine={false}
                    type="number"
                  />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
          <BottomArrow style={{ marginTop: '16px' }} to="number-of-homes" />
        </div>
      )}
    </InView>
  )
}
