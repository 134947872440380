export const CHART_MARGIN_SMALL = { top: 30, bottom: 10, left: 20, right: 20 }
export const CHART_MARGIN_LARGE = { top: 50, bottom: 50, left: 50, right: 50 }

export const getMargin = () =>
  window.innerWidth < 800 && window.innerWidth > 450
    ? CHART_MARGIN_SMALL
    : CHART_MARGIN_LARGE

const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1

export const CustomLabel = ({ x, y, value, formatter }) => (
  <text
    // BUG in safari when positioning text
    dy={isSafari ? -35 : -25}
    fill={'currentColor'}
    style={{
      fontSize: '80%',
      animation: 'fadeIn 1s forwards cubic-bezier(.15,.65,.24,.94)',
    }}
    textAnchor="middle"
    x={x}
    y={y}
  >
    {formatter(value)}
  </text>
)

CustomLabel.defaultProps = {
  formatter: (x) => x,
}
