import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import styles from './modal.module.css'

function Modal({ isOpen, close, children, ...props }) {
  if (!isOpen) return null
  return (
    <div className={styles.modal}>
      <div className={styles.backdrop} onClick={close}></div>
      <div {...props} className={`${styles.content} ${props.className}`}>
        <div className={styles.modalHeader}>
          <button onClick={close}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {}

export default Modal
