import { faSignHanging } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { useWindowSize } from 'use-window-size-hook'

import { CustomLabel, getMargin } from '../../chartUtil'
import BottomArrow from '../../components/BottomArrow/BottomArrow'
import { PageHeader } from '../../components/Pageheader/PageHeader'
import { COLORS } from '../../constants'
import useData from '../../context/dataContext'
import { convertMonths, toListOfObjects } from '../../dataUtils'

import styles from './numberOfHomes.module.css'

export default function NumberOfHomes() {
  const {
    screenLayout: { isMdOrAbove },
  } = useWindowSize()
  const data = useData()
  const timeSeries = data?.stats?.num_sold
  const chartData = useMemo(
    () =>
      timeSeries &&
      toListOfObjects({
        ...timeSeries,
        x: convertMonths(timeSeries.x),
      }),
    [timeSeries]
  )
  if (!timeSeries) return null

  const housingTypeLabel =
    data.housing_type_group === 'apartments' ? 'lägenheter' : 'hus'

  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div
          className="container"
          ref={ref}
          style={{
            color: COLORS.blue,
          }}
        >
          <PageHeader
            faIcon={faSignHanging}
            title={`Sålda ${housingTypeLabel}`}
            titleProps={{ className: styles.heading }}
          />
          <ResponsiveContainer className="chart">
            <LineChart
              data={chartData}
              margin={getMargin()}
              style={{ fontSize: '18px' }}
              width={730}
            >
              {inView && (
                <>
                  <Line
                    dataKey="y"
                    dot={{
                      stroke: COLORS.blue,
                      fill: COLORS.blue,
                      strokeWidth: isMdOrAbove ? 7 : 3,
                    }}
                    fill={COLORS.white}
                    label={CustomLabel}
                    stroke={COLORS.blue}
                    strokeWidth={isMdOrAbove ? 5 : 3}
                  />

                  <XAxis
                    axisLine={false}
                    dataKey="x"
                    stroke={COLORS.blue}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    dataKey="y"
                    domain={[
                      (dataMin) => dataMin - 4,
                      (dataMax) => dataMax + 4,
                    ]}
                    hide={true}
                    tickLine={false}
                    type="number"
                  />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
          <BottomArrow style={{ marginTop: '16px' }} to="options" />
        </div>
      )}
    </InView>
  )
}
