export const ScoreDownwards = () => (
  <svg
    fill="none"
    height="70"
    style={{ transform: 'rotate(15deg)' }}
    viewBox="0 0 84 70"
    width="84"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.6188 53.1697C46.9472 52.4538 44.2105 54.0339 43.4946 56.7055C42.7788 59.3771 44.3588 62.1138 47.0304 62.8297L71.1805 69.3007C73.8521 70.0165 76.5888 68.4364 77.3047 65.7649L83.7757 41.6148C84.4915 38.9432 82.9114 36.2065 80.2399 35.4906C77.5683 34.7748 74.8315 36.3548 74.1156 39.0264L70.771 51.509L52.0492 19.1041C50.668 16.7118 47.604 15.8908 45.2117 17.272L25.0593 28.907L9.87336 2.6263C8.49218 0.23403 5.42815 -0.586976 3.03588 0.7942C0.643615 2.17538 -0.17739 5.23941 1.20379 7.63168L18.8828 38.2527C20.264 40.645 23.328 41.466 25.7203 40.0848L45.8919 28.4387L62.1014 56.5144L49.6188 53.1697Z"
      fill="currentColor"
    />
  </svg>
)

export const ScoreSlightlyDownwards = () => (
  <svg
    fill="none"
    height="50"
    viewBox="0 0 91 50"
    width="91"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.0164 39.9992C57.2506 39.9992 55.016 42.2338 55.016 44.9996C55.016 47.7654 57.2506 50 60.0164 50H85.0184C87.7842 50 90.0188 47.7654 90.0188 44.9996V19.9977C90.0188 17.2318 87.7842 14.9973 85.0184 14.9973C82.2525 14.9973 80.018 17.2318 80.018 19.9977V32.9205L53.5472 6.46535C51.5939 4.51207 48.4217 4.51207 46.4685 6.46535L30.0141 22.9198L8.54364 1.46496C6.59036 -0.488319 3.41824 -0.488319 1.46496 1.46496C-0.488319 3.41824 -0.488319 6.59036 1.46496 8.54364L26.4669 33.5456C28.4202 35.4989 31.5923 35.4989 33.5456 33.5456L50.0156 17.0756L72.9393 39.9992H60.0164Z"
      fill="currentColor"
    />
  </svg>
)

export const ScoreNeutral = () => (
  <svg
    fill="none"
    height="50"
    viewBox="0 0 80 50"
    width="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78.4945 28.5296C80.4467 26.5774 80.4467 23.407 78.4945 21.4548L58.5038 1.46416C56.5516 -0.488052 53.3812 -0.488052 51.429 1.46416C49.4768 3.41637 49.4768 6.58676 51.429 8.53897L62.8924 20.0023H4.99766C2.23333 20.0023 0 22.2357 0 25C0 27.7643 2.23333 29.9977 4.99766 29.9977H62.8924L51.429 41.461C49.4768 43.4132 49.4768 46.5836 51.429 48.5358C53.3812 50.4881 56.5516 50.4881 58.5038 48.5358L78.4945 28.5452V28.5296Z"
      fill="currentColor"
    />
  </svg>
)

export const ScoreSlightlyUpwards = () => (
  <svg
    fill="none"
    height="50"
    viewBox="0 0 91 50"
    width="91"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.0164 10.0008C57.2506 10.0008 55.016 7.76623 55.016 5.00039C55.016 2.23455 57.2506 0 60.0164 0L85.0184 0C87.7842 0 90.0188 2.23455 90.0188 5.00039V30.0023C90.0188 32.7682 87.7842 35.0027 85.0184 35.0027C82.2525 35.0027 80.018 32.7682 80.018 30.0023V17.0795L53.5472 43.5347C51.5939 45.4879 48.4217 45.4879 46.4685 43.5347L30.0141 27.0802L8.54364 48.535C6.59036 50.4883 3.41824 50.4883 1.46496 48.535C-0.488319 46.5818 -0.488319 43.4096 1.46496 41.4564L26.4669 16.4544C28.4202 14.5011 31.5923 14.5011 33.5456 16.4544L50.0156 32.9244L72.9393 10.0008H60.0164Z"
      fill="currentColor"
    />
  </svg>
)

export const ScoreUpwards = () => (
  <svg
    fill="none"
    height="71"
    style={{ transform: 'rotate(-15deg)' }}
    viewBox="0 0 84 71"
    width="84"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.5598 17.0085C46.8882 17.7243 44.1514 16.1442 43.4356 13.4726C42.7197 10.8011 44.2998 8.0643 46.9714 7.34845L71.1214 0.877464C73.793 0.161613 76.5298 1.74168 77.2456 4.41327L83.7166 28.5633C84.4325 31.2349 82.8524 33.9717 80.1808 34.6875C77.5092 35.4034 74.7725 33.8233 74.0566 31.1517L70.7119 18.6692L51.9902 51.0741C50.609 53.4663 47.545 54.2873 45.1527 52.9062L25.0002 41.2711L9.81431 67.5518C8.43314 69.9441 5.3691 70.7651 2.97683 69.3839C0.584567 68.0028 -0.236439 64.9387 1.14474 62.5464L18.8238 31.9254C20.205 29.5332 23.269 28.7122 25.6613 30.0933L45.8329 41.7394L62.0423 13.6638L49.5598 17.0085Z"
      fill="currentColor"
    />
  </svg>
)
