import { cubicBezier, Gauge as GaugeJS } from 'gauge-chart-js'
import React, { useEffect, useRef } from 'react'

import styles from './gauge.module.css'

const toAngle = 450
const fromAngle = 270

const getGaugeConfig = (color = 'currentColor', gaugeContainerRef) => ({
  container: gaugeContainerRef.current,
  color,
  fromAngle,
  toAngle,
  lineWidth: 2.3,
  gaugeRadius: 33,
  animationDelay: 200,
  easing: cubicBezier(0.15, 0.65, 0.24, 0.94),
})

export default function Gauge({ score, inView, Icon }) {
  const gaugeRef = useRef()
  const gaugeContainerRef = useRef()

  useEffect(() => {
    if (!gaugeContainerRef.current || gaugeRef.current) return

    const bgGauge = new GaugeJS({
      ...getGaugeConfig('#D9D9D9', gaugeContainerRef),
      animationDuration: 0,
    })
    const gauge = new GaugeJS(getGaugeConfig(undefined, gaugeContainerRef))
    bgGauge.setValue(toAngle)

    gaugeRef.current = gauge
    return () => {
      if (gaugeContainerRef.current) {
        gaugeContainerRef.current.innerHTML = ''
      }
      gauge.dispose()
      bgGauge.dispose()
      gaugeRef.current = null
    }
  }, [gaugeContainerRef.current])

  useEffect(() => {
    if (score === null || score === undefined || !inView) {
      gaugeRef.current.setValue(0)
      return
    }

    gaugeRef.current.setValue((toAngle - fromAngle) * score)
  }, [gaugeRef, score, inView])
  return (
    <>
      <div className={styles.container} ref={gaugeContainerRef} />
      <div className={styles.icon}>
        <Icon />
      </div>
    </>
  )
}
