import React, { useCallback, useState } from 'react'

import useData from '../../context/dataContext'
import useFormData from '../../context/formContext'
import { sendFeedback } from '../../services/firestore'
import Modal from '../Modal/Modal'

import styles from './feedback.module.css'

export default function Feedback({ onHide }) {
  const initialFormData = useFormData()
  const data = useData()
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      setSubmitting(true)
      setSuccess(null)
      setError(null)
      const formData = new FormData(e.target)
      sendFeedback({
        ...Object.fromEntries(formData.entries()),
        userAgent: navigator.userAgent,
        initialFormData,
        data,
      })
        .then(() => setSuccess(true))
        .then(() => e.target.reset())
        .catch((error) => setError(error))
        .finally(() => setSubmitting(false))
    },
    [initialFormData, data]
  )

  return (
    <Modal isOpen className={styles.content} close={onHide}>
      <form id="tracking-feedback-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="feedback-textarea">Meddelande*</label>
          <textarea
            required
            id="feedback-textarea"
            name="message"
            rows={5}
          ></textarea>
        </div>
        <div>
          <label>E-post (valfri)</label>
          <input name="email" type="email" />
        </div>
        <input
          disabled={submitting}
          type="submit"
          value={submitting ? 'Skickar...' : 'Skicka'}
        />
        {success && <p>Skickat!</p>}
        {error && <p>Något gick fel: {error.message}</p>}
      </form>
    </Modal>
  )
}
