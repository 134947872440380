import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useInView } from 'react-intersection-observer'

import BottomArrow from '../../components/BottomArrow/BottomArrow'
import Gauge from '../../components/Gauge/Gauge'
import { PageHeader } from '../../components/Pageheader/PageHeader'
import { COLORS } from '../../constants'
import useData from '../../context/dataContext'

import styles from './score.module.css'
import { partialScoreTexts, thresholdToScoreData } from './texts'
import { VerticalSeparator } from './VerticalSeparator'

const URL = 'https://www.bomarknaden.nu/?utm_source=shared'

// exact values used for tracking (prefixed with "tracking-")
const sharingMode = isMobile && navigator.share ? 'native' : 'clipboard'

const housingTypeLabels = { apartments: 'lägenheter', houses: 'hus' }

const formatPostalCode = (postalCode) => {
  if (!postalCode || postalCode.toString().length !== 5) return postalCode
  const postalCodeStr = postalCode.toString()
  return postalCodeStr.slice(0, 3) + ' ' + postalCodeStr.slice(3)
}

const generateShareText = ({
  housingType,
  postalCode,
  summaryTextFn,
  emoji,
}) => {
  const postal = `Postnummer ${formatPostalCode(postalCode)}`
  const text = summaryTextFn(housingTypeLabels[housingType])
  return `${postal}\n${text} ${emoji}`
}

export function Score() {
  const { ref, inView } = useInView({ threshold: 0.9, triggerOnce: true })
  const {
    housing_type_group: housingType,
    partial_scores: partialScores,
    postal_code: postalCode,
    score,
  } = useData() || {}

  useEffect(() => {
    if (score !== null) setTimeout(() => (window.location.hash = 'score'), 300)
  }, [score])

  const scoreData = useMemo(
    () =>
      thresholdToScoreData.filter(
        ({ from, to }) => score >= from && score <= to
      )?.[0],
    [score]
  )

  const [showTooltip, setShowTooltip] = useState(false)

  const onShare = useCallback(() => {
    if (!scoreData) return

    const shareText = generateShareText({
      ...scoreData,
      housingType,
      postalCode,
    })

    if (sharingMode === 'native') {
      return navigator.share({
        url: URL,
        text: shareText,
      })
    } else {
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 1500)
      return navigator.clipboard.writeText(`${shareText}\n${URL}`)
    }
  }, [scoreData, postalCode, setShowTooltip, housingType])

  if (!score || !scoreData) {
    return (
      <div className="container" style={{ color: COLORS.blue }}>
        <h1 style={{ marginBottom: '20px' }}>Trenden saknas tyvärr</h1>
        <p style={{ fontWeight: 'bold' }}>Skrolla ner för att se detaljer</p>
      </div>
    )
  }

  return (
    <div className="container" style={{ color: COLORS.blue }}>
      <div ref={ref} style={{ color: scoreData.color }}>
        <Gauge
          Icon={scoreData.image}
          inView={inView}
          score={score}
          scoreData={scoreData}
        />
      </div>
      <div className={styles.contentWrapper}>
        <PageHeader
          title={
            <>
              {scoreData.title}
              <span className={styles.shareseparator}>
                <VerticalSeparator />
              </span>
              <button
                className={styles.sharebtn}
                id={`tracking-sharing-${sharingMode}`}
                title="Dela resultat"
                onClick={onShare}
              >
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  style={{ fontSize: '24px' }}
                />
                {showTooltip && <div>Kopierat!</div>}
              </button>
            </>
          }
          titleProps={{
            className: `${styles.scoretitle} ${
              styles[scoreData.title.split(' ').join('-').toLowerCase()]
            }`,
            style: {
              marginBlock: 0,
            },
          }}
        />
        <p>{scoreData.summaryTextFn(housingTypeLabels[housingType])}</p>
        <p>{getPartialScoreText(partialScores)}</p>
        <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
          Skrolla ner för att se detaljer
        </p>
      </div>
      <BottomArrow style={{ marginTop: '45px' }} to="square-meter-price" />
    </div>
  )
}

function getPartialScoreText(partialScores) {
  function getTrendCharacter(score, reversed) {
    if (score < 0.45) return reversed ? '+' : '-'
    if (score > 0.55) return reversed ? '-' : '+'
    return '/'
  }

  const textKey = [
    getTrendCharacter(partialScores['score_price_per_m2'], false),
    getTrendCharacter(partialScores['score_days_on_market'], true),
    getTrendCharacter(partialScores['score_num_sold'], false),
  ].join(',')

  // TODO consider adding something like this back
  // const emphasis = partialScoreMapOrder
  //   .map(
  //     (key) =>
  //       partialScores[key] < MUCH_LIMIT || partialScores[key] > 1 - MUCH_LIMIT
  //   )
  //   .map((isMuch, index) => emphasisOptions[isMuch ? 'much' : 'some'][index])

  const emphasis = {
    price_per_m2: '',
    days_on_market: '',
    num_sold: '',
  }
  return Object.entries(emphasis).reduce(
    (template, [key, value]) => template.replace(`{${key}}`, value),
    partialScoreTexts[textKey]
  )
}
