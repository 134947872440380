import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './pageHeader.module.css'

export const PageHeader = ({
  title,
  titleProps = {},
  faIcon,
  color,
  city = '',
}) => (
  <div className={styles.container}>
    {faIcon && <FontAwesomeIcon color={color} icon={faIcon} />}
    <h2 style={{ color, ...titleProps.style }} {...titleProps}>
      {title}
    </h2>
    {city && (
      <div
        style={{ marginBottom: '60px', color, opacity: 0.75, fontSize: '90%' }}
      >
        {city}
      </div>
    )}
  </div>
)
